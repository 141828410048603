<template>
    <div class="SchoolCenter">
        <!-- {{$i18n.message.项目}} -->
        <div id="SchoolCenter" style="min-height:800px;width: 100%"></div>
        <!-- <div class="alert-top">
                <div><i class="fa fa-map-marker fa-lg" style="font-size: 18px;color: #097FFF"></i><span>我的机构</span></div>
                <div><i class="fa fa-map-marker fa-lg" style="font-size: 18px;color: #7672F9"></i><span>他们机构</span></div>
            </div> -->
        <div class="left-container" ref="totalHeight">
            <transition name="show">
                <div
                        class="left-main"
                        v-show="leftShow"
                        :style="{ 'overflow-y': 'auto', height: contentHeight + 'px' }"
                >
                    <div class="content" style="padding: 20px 0px;margin:0px 15px;">
                        <a-form>
                            <a-form-item v-bind="formItemLayout" label="选择中心">
                                <a-tree-select
                                        style="width: 100%"
                                        :treeData="treeDataSchool"
                                        treeNodeFilterProp="title"
                                        :value="schoolVal"
                                        @change="onChangeSchool"
                                        treeCheckable
                                        :showCheckedStrategy="SHOW_PARENT"
                                        placeholder="请选择中心"
                                />
                            </a-form-item>
                            <a-form-item v-bind="formItemLayout" label="分类">
                                <a-select
                                        style="width: 100%"
                                        v-model="type_val"
                                        @change="meList"
                                        placeholder="请选择"
                                >
                                    <a-select-option value="0">筹备中</a-select-option>
                                    <a-select-option value="1">已开业</a-select-option>
                                </a-select>
                            </a-form-item>
                            <a-form-item v-bind="formItemLayout" label="地理位置">
                                <a-input-search
                                        placeholder="请输入要转换的地理位置"
                                        enter-button
                                        @search="transXYFn"
                                />
                            </a-form-item>
                        </a-form>
                        <!--                       <div class="row" style="text-align: right;display: block">
                                            <a-button type="primary">开始查询</a-button>
                                        </div> -->

                        <div class="row">
                            <span class="list-title">机构列表({{ listArr.length }})</span>
                        </div>

                        <div class="list-content">
                            <ul>
                                <li
                                        v-for="(item, k) in listArr"
                                        :key="k"
                                        @click="schoolDetailsFn(item.Id)"
                                >
                                    <div
                                            :class="{
                      line: true,
                      'ant-btn-primary': item.OrgName.indexOf('筹备中') < 0,
                      'line-gray': item.OrgName.indexOf('筹备中') > 0
                    }"
                                    ></div>
                                    <div class="list-name">
                    <span
                    >{{
                        item.OrgName.indexOf('筹备中') > 0
                          ? item.OrgName.substr(
                              0,
                              item.OrgName.indexOf('筹备中') - 1
                            )
                          : item.OrgName
                      }}
                      <span
                              :class="{
                          'ant-btn-primary': item.OrgName.indexOf('筹备中') < 0,
                          'line-gray': item.OrgName.indexOf('筹备中') > 0
                        }"
                              style="padding:3px 10px 2px;font-size:10px;border-radius:16px;color:#fff;font-size:10px; vertical-align: text-bottom;"
                      >{{
                          item.OrgName.indexOf('筹备中') > 0
                            ? '筹备中'
                            : '已开业'
                        }}</span
                      ></span
                    >
                                        <span
                                                v-show="item.unfilled_number != 0"
                                                style="border-radius:50%; font-size:10px;color:#fff;background:#F5222D;min-width:18px;text-align:center;"
                                        >-{{ item.unfilled_number }}</span
                                        >
                                    </div>
                                    <div class="site">地址：{{ item.Addr }}</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <!-- <div class="add-btn ant-btn-primary post_map" @click="onClose"><a-icon type="plus" /></div> -->
                </div>
            </transition>
            <div
                    class="cut-btn"
                    @click="_cutShow"
                    @mouseover="_hintShow"
                    @mouseout="_hintShow"
            >
                <a-icon
                        :type="leftShow ? 'caret-left' : 'caret-right'"
                        style="color:#8A8A8A"
                />
                <div class="hint-msg" v-show="hintMsg">
                    <div class="san"></div>
                    <span>{{ leftShow ? '收起侧边面板' : '展开侧边面板' }}</span>
                </div>
            </div>
        </div>
        <a-drawer
                :title="orgName"
                placement="right"
                @close="onClose"
                :visible="visible"
                width="720"
        >
            <div class="drawer-content">
                <a-form>
                    <a-row :gutter="24">
                        <a-col :span="24"
                        >
                            <div class="list-title border-r zIndex">基本信息</div>
                        </a-col
                        >
                        <a-col :span="12">
                            <a-form-item v-bind="formItemLayout" label="中心名称">
                                <a-input
                                        v-model="orgName"
                                        :disabled="true"
                                        placeholder="请输入"
                                        style="width: 100%"
                                />
                            </a-form-item>
                        </a-col>
                        <a-col :span="12">
                            <a-form-item
                                    v-bind="formItemLayout"
                                    label="中心简写"
                                    :class="{ activeColor: !centerCode }"
                            >
                                <a-input
                                        v-model="centerCode"
                                        placeholder="请输入"
                                        style="width: 100%"
                                />
                            </a-form-item>
                        </a-col>
                        <a-col :span="12">
                            <a-form-item
                                    v-bind="formItemLayout"
                                    label="所属大区"
                                    :class="{ activeColor: !region }"
                            >
                                <a-input
                                        v-model="region"
                                        placeholder="请输入"
                                        style="width: 100%"
                                />
                            </a-form-item>
                        </a-col>
                        <a-col :span="12">
                            <a-form-item
                                    v-bind="formItemLayout"
                                    label="城市等级"
                                    :class="{ activeColor: !level }"
                            >
                                <a-select
                                        style="width: 100%"
                                        v-model="level"
                                        placeholder="请选择"
                                >
                                    <a-select-option
                                            v-for="(item, k) in partnerType == 2
                      ? level_arrAdd
                      : level_arr"
                                            :key="k"
                                            :value="item.value"
                                    >{{ item.name }}
                                    </a-select-option
                                    >
                                </a-select>
                            </a-form-item>
                        </a-col>
                        <a-col :span="12">
                            <a-form-item
                                    v-bind="formItemLayout"
                                    label="官网显示"
                                    :class="{ activeColor: !displayCode }"
                            >
                                <a-select
                                        style="width: 100%"
                                        v-model="displayCode"
                                        placeholder="请选择"
                                >
                                    <a-select-option
                                            v-for="(item, k) in display_arr"
                                            :key="k"
                                            :value="item.value"
                                    >{{ item.name }}
                                    </a-select-option
                                    >
                                </a-select>
                            </a-form-item>
                        </a-col>
                        <a-col :span="12">
                            <a-form-item
                                    v-bind="formItemLayout"
                                    label="位置"
                                    :class="{ activeColor: !province }"
                            >
                                <a-cascader
                                        :options="province_arr"
                                        v-model="province"
                                        @change="ChangeAddr"
                                />
                            </a-form-item>
                        </a-col>
                        <a-col :span="12">
                            <a-form-item
                                    v-bind="formItemLayout"
                                    label="街道"
                                    :class="{ activeColor: !town }"
                            >
                                <a-input
                                        v-model="town"
                                        placeholder="请输入"
                                        style="width: 100%"
                                />
                            </a-form-item>
                        </a-col>
                        <a-col :span="12">
                            <a-form-item
                                    v-bind="formItemLayout"
                                    label="详细地址"
                                    :class="{ activeColor: !detailAddress }"
                            >
                                <a-input
                                        v-model="detailAddress"
                                        placeholder="请输入"
                                        style="width: 100%"
                                />
                            </a-form-item>
                        </a-col>
                        <a-col :span="12">
                            <a-form-item
                                    v-bind="formItemLayout"
                                    label="负责人"
                                    :class="{ activeColor: !chargeId }"
                            >
                                <div style="position:relative;padding-top:4px;">
                                    <div
                                            style="position:absolute;width:100%;z-index:10;opacity:0"
                                    >
                                        <person v-bind="personObj" @getData="getData"></person>
                                    </div>
                                    <div
                                            style="text-indent:10px;border:1px solid #ebebeb;border-radius:4px;height:32px;line-height:30px;"
                                    >
                                        {{ chargeId ? chargeId.split('|')[2] : '' }}
                                    </div>
                                </div>
                            </a-form-item>
                        </a-col>
                        <a-col :span="12">
                            <a-form-item
                                    v-bind="formItemLayout"
                                    label="经纬度"
                                    :class="{ activeColor: !mapPoint }"
                            >
                                <a-input
                                        v-model="mapPoint"
                                        placeholder="格式如 113.936979,22.524739"
                                        style="width: 100%"
                                />
                            </a-form-item>
                        </a-col>
                        <a-col :span="12">
                            <a-form-item
                                    v-bind="formItemLayout"
                                    label="中心电话"
                                    :class="{ activeColor: !phone }"
                            >
                                <a-input
                                        v-model="phone"
                                        placeholder="请输入"
                                        style="width: 100%"
                                />
                            </a-form-item>
                        </a-col>
                        <a-col :span="12">
                            <a-form-item
                                    v-bind="formItemLayout"
                                    label="中心停车位"
                                    :class="{ activeColor: !parkingSpace }"
                            >
                                <a-input
                                        v-model="parkingSpace"
                                        placeholder="请输入数字"
                                        style="width: 100%"
                                />
                            </a-form-item>
                        </a-col>
                        <a-col :span="12">
                            <a-form-item v-bind="formItemLayout" label="中心Code">
                                <a-input
                                        v-model="schoolCode"
                                        :disabled="true"
                                        placeholder="请输入"
                                        style="width: 100%"
                                />
                            </a-form-item>
                        </a-col>
                        <a-col :span="12">
                            <a-form-item v-bind="formItemLayout" label="中心教室数量">
                                <a-input
                                        v-model="classRoomNum"
                                        :disabled="false"
                                        placeholder="11"
                                        style="width: 100%"
                                />
                            </a-form-item>
                        </a-col>

                        

                        <a-col :span="12">
                            <a-form-item
                                    v-bind="formItemLayout"
                                    label="中心照片"
                                    :class="{ activeColor: centerList.length < 1 }"
                            >
                                <div class="clearfix">
                                    <a-upload
                                            accept="image/*"
                                            listType="picture-card"
                                            :fileList="centerList"
                                            @preview="handlePreview"
                                            @change="handleChange"
                                            :remove="handleRemove"
                                            :beforeUpload="beforeUpload"
                                    >
                                        <div v-if="centerList.length < 9">
                                            <a-icon type="plus"/>
                                            <!--action="https://www.mocky.io/v2/5cc8019d300000980a055e76"  <div class="ant-upload-text">Upload</div> -->
                                        </div>
                                    </a-upload>
                                    <a-modal
                                            :visible="previewVisible"
                                            :footer="null"
                                            @cancel="handleCancel"
                                    >
                                        <img
                                                alt="example"
                                                style="width: 100%"
                                                :src="previewImage"
                                        />
                                    </a-modal>
                                </div>
                            </a-form-item>
                        </a-col>
                        <a-col :span="12">
                            <a-form-item
                                    v-bind="formItemLayout"
                                    label="中心地图"
                                    :class="{ activeColor: mapList.length < 1 }"
                            >
                                <div class="clearfix">
                                    <a-upload
                                            accept="image/*"
                                            listType="picture-card"
                                            :fileList="mapList"
                                            @preview="handlePreview"
                                            @change="handleChangeMap"
                                            :remove="handleRemoveMap"
                                            :beforeUpload="beforeUploadMap"
                                    >
                                        <div v-if="mapList.length < 9">
                                            <a-icon type="plus"/>
                                            <!--action="https://www.mocky.io/v2/5cc8019d300000980a055e76"  <div class="ant-upload-text">Upload</div> -->
                                        </div>
                                    </a-upload>
                                    <a-modal
                                            :visible="previewVisible"
                                            :footer="null"
                                            @cancel="handleCancel"
                                    >
                                        <img
                                                alt="example"
                                                style="width: 100%"
                                                :src="previewImage"
                                        />
                                    </a-modal>
                                </div>
                            </a-form-item>
                        </a-col>
                        <a-col :span="12">
                            <a-form-item
                                    v-bind="formItemLayout"
                                    label="中心公章"
                                    :class="{ activeColor: sealList.length < 1 }"
                            >
                                <div class="clearfix">
                                    <a-upload
                                            accept="image/*"
                                            listType="picture-card"
                                            :fileList="sealList"
                                            @preview="handlePreview"
                                            @change="handleChangeSeal"
                                            :remove="handleRemoveSeal"
                                            :beforeUpload="beforeUploadSeal"
                                    >
                                        <div v-if="sealList.length < 9">
                                            <a-icon type="plus"/>
                                            <!--action="https://www.mocky.io/v2/5cc8019d300000980a055e76"  <div class="ant-upload-text">Upload</div> -->
                                        </div>
                                    </a-upload>
                                    <a-modal
                                            :visible="previewVisible"
                                            :footer="null"
                                            @cancel="handleCancel"
                                    >
                                        <img
                                                alt="example"
                                                style="width: 100%"
                                                :src="previewImage"
                                        />
                                    </a-modal>
                                </div>
                            </a-form-item>
                        </a-col>
                        <a-col :span="12">
                            <a-form-item
                                    v-bind="formItemLayout"
                                    :colon="colon"
                                    :class="{ activeColor: BusinessLicenseList.length < 1 }"
                            >
                                <span slot="label"style="margin-right: 5px;display: block" >
                                    <div>营业执照</div>
                                    <div>正/副本:</div>

                                </span>
                                <div class="clearfix">
                                    <a-upload

                                            listType="picture-card"
                                            :fileList="BusinessLicenseList"
                                            @preview="handlePreview"
                                            @change="handleChangeBusiness"
                                            :remove="handleRemoveBusiness"
                                            :beforeUpload="beforeUploadBusinessLicense"
                                    >
                                        <div v-if="BusinessLicenseList.length < 9">
                                            <a-icon type="plus"/>
                                        </div>
                                    </a-upload>
                                    <a-modal
                                            :visible="previewVisible"
                                            :footer="null"
                                            @cancel="handleCancel"
                                    >
                                        <img
                                                alt="example"
                                                style="width: 100%"
                                                :src="previewImage"
                                        />
                                    </a-modal>
                                </div>
                            </a-form-item>
                        </a-col>



                        <a-col :span="12">
                            <a-form-item v-bind="formItemLayout" label="描述">
                                <a-textarea
                                        v-model="intro"
                                        placeholder="请输入"
                                        :rows="4"
                                ></a-textarea>
                            </a-form-item>
                        </a-col>
                        <a-col :span="12">
                            <a-form-item
                                    v-bind="formItemLayout"
                                    label="营业信息"
                                    :class="{ activeColor: !BusinessInfo }"
                            >
                                <a-input
                                        v-model="BusinessInfo"
                                        placeholder="请输入"
                                        style="width: 100%"
                                />
                            </a-form-item>
                        </a-col>
                        <a-col :span="12">
                            <a-form-item v-bind="formItemLayout" label="所属公司">
                                <a-input
                                        v-model="company"
                                        :disabled="false"
                                        placeholder="所属公司"
                                        style="width: 100%"
                                />
                            </a-form-item>
                        </a-col>
                    </a-row>
                    <div style="margin:10px;text-align:center;">
                        <!-- <a-button type="dashed" >
                                        <a-icon type="plus" />添加
                                    </a-button> -->
                        <a-button
                                type="primary"
                                style="margin-left:20px;"
                                @click="infoFn()"
                        >
                            保 存
                        </a-button>
                    </div>
                    <a-row :gutter="24">
                        <a-col :span="24"
                        >
                            <div class="list-title border-r zIndex">施工信息</div>
                        </a-col
                        >
                        <a-col :span="12">
                            <a-form-item
                                    v-bind="formItemLayout"
                                    label="开业时间"
                                    :class="{ activeColor: !openingTime }"
                            >
                                <a-date-picker
                                        v-model="openingTime"
                                        @change="birthdayChange"
                                        :defaultValue="moment(openingTime, 'YYYY-MM-DD')"
                                        placeholder="请选择"
                                        format="YYYY-MM-DD"
                                        style="width:100%;"
                                />
                            </a-form-item>
                        </a-col>
                        <a-col :span="12">
                            <a-form-item
                                    v-bind="formItemLayout"
                                    label="装修时间"
                                    :class="{ activeColor: !buildDate }"
                            >
                                <a-date-picker
                                        style="width:100%;"
                                        @change="birthdayChange"
                                        v-model="buildDate"
                                        :defaultValue="moment(buildDate, 'YYYY-MM-DD')"
                                        placeholder="请选择"
                                        format="YYYY-MM-DD"
                                        :disabledDate="disabledDate"
                                />
                            </a-form-item>
                        </a-col>
                        <a-col :span="12">
                            <a-form-item
                                    v-bind="formItemLayout"
                                    label="鉴定日期"
                                    :class="{ activeColor: !signDate }"
                            >
                                <a-date-picker
                                        style="width:100%;"
                                        @change="birthdayChange"
                                        v-model="signDate"
                                        :defaultValue="moment(signDate, 'YYYY-MM-DD')"
                                        placeholder="请选择"
                                        format="YYYY-MM-DD"
                                        :disabledDate="disabledDate"
                                />
                            </a-form-item>
                        </a-col>
                        <a-col :span="12">
                            <a-form-item
                                    v-bind="formItemLayout"
                                    label="试营业时间"
                                    :class="{ activeColor: !trialOpeningTime }"
                            >
                                <a-date-picker
                                        style="width:100%;"
                                        @change="birthdayChange"
                                        v-model="trialOpeningTime"
                                        :defaultValue="moment(trialOpeningTime, 'YYYY-MM-DD')"
                                        placeholder="请选择"
                                        format="YYYY-MM-DD"
                                        :disabledDate="disabledDate"
                                />
                            </a-form-item>
                        </a-col>
                        <a-col :span="12">
                            <a-form-item
                                    v-bind="formItemLayout"
                                    label="交工时间"
                                    :class="{ activeColor: !completedDate }"
                            >
                                <a-date-picker
                                        style="width:100%;"
                                        @change="birthdayChange"
                                        v-model="completedDate"
                                        :defaultValue="moment(completedDate, 'YYYY-MM-DD')"
                                        placeholder="请选择"
                                        format="YYYY-MM-DD"
                                        :disabledDate="disabledDate"
                                />
                            </a-form-item>
                        </a-col>
                        <a-col :span="12">
                            <a-form-item
                                    v-bind="formItemLayout"
                                    label="面积(平方)"
                                    :class="{ activeColor: !value }"
                            >
                                <a-tooltip
                                        :trigger="['focus']"
                                        placement="topLeft"
                                        overlayClassName="numeric-input"
                                >
                  <span slot="title" v-if="value" class="numeric-input-title">
                    {{ value !== '-' ? formatNumber(value) : '-' }}
                  </span>
                                    <template slot="title" v-else>
                                        请输入正整数
                                    </template>
                                    <a-input
                                            :value="value"
                                            @change="onChange"
                                            @blur="onBlur"
                                            placeholder="请输入正整数"
                                            :maxLength="10"
                                            style="width: 100%"
                                    />
                                </a-tooltip>
                            </a-form-item>
                        </a-col>
                    </a-row>
                    <div style="margin:10px;text-align:center;">
                        <!-- <a-button type="dashed" >
                                        <a-icon type="plus" />添加
                                    </a-button> -->
                        <a-button
                                type="primary"
                                @click="constructionFn()"
                                style="margin-left:20px;"
                        >
                            保 存
                        </a-button>
                    </div>
                </a-form>
            </div>
        </a-drawer>
        <div v-show="spinning" style="position:fixed;left:50%;top: 0px;">
            <a-spin style="margin: 150px 0%;">
                <a-icon
                        slot="indicator"
                        type="loading"
                        :spinning="spinning"
                        style="font-size: 44px;color:red;"
                        spin
                />
            </a-spin>
        </div>
    </div>
</template>

<script>
    import {TreeSelect} from 'ant-design-vue'

    const SHOW_PARENT = TreeSelect.SHOW_PARENT
    import moment from 'moment'
    import BMap from 'BMap'

    const userInfo = JSON.parse(window.sessionStorage.getItem('userInfo'))
    import {async} from 'q'

    function changeTime(time) {
        let date1 = new Date(time)
        let birthday =
            date1.getFullYear() +
            '-' +
            (date1.getMonth() + 1 < 10
                ? '0' + (date1.getMonth() + 1)
                : date1.getMonth() + 1) +
            '-' +
            (date1.getDate() < 10 ? '0' + date1.getDate() : date1.getDate())
        return birthday
    }

    function formatNumber(value) {
        value += ''
        const list = value.split('.')
        const prefix = list[0].charAt(0) === '-' ? '-' : ''
        let num = prefix ? list[0].slice(1) : list[0]
        let result = ''
        while (num.length > 3) {
            result = `,${num.slice(-3)}${result}`
            num = num.slice(0, num.length - 3)
        }
        if (num) {
            result = num + result
        }
        return `${prefix}${result}${list[1] ? `.${list[1]}` : ''}`
    }

    function imgObjFn(str) {
        let objArr = []
        if (!str) {
            return objArr
        }
        let arr = str.split(',')
        for (let i = 0; i < arr.length; i++) {
            let obj = {
                lastModified: 1575944562033,
                lastModifiedDate: '',
                name: '2019-12-10-102232.png',
                originFileObj: 'File',
                percent: 0,
                size: 9020,
                thumbUrl: '',
                type: 'image/png',
                uid: 'vc-upload-1576225313806-2',
                url: arr[i]
            }
            objArr.push(obj)
        }
        return objArr
    }

    var type_Arr = [] //监听分类帅选条件
    import person from '@/components/SelectPersonnel/SelectPersonnel' //选人
    export default {
        name: 'SchoolCenter',
        components: {person},
        data() {
            return {
                spinning: false,
                formItemLayout: {
                    labelCol: {span: 7},
                    wrapperCol: {span: 17}
                },
                personObj: {
                    rank: 0, //职级 0为不选，1为选
                    nums: 1, //选的数量
                    department: 0, //0不选部门，1为都选（人 部门都选），2为只选部门，选人
                    contacts: 'recent11', //常用联系人recent 不要就传其他
                    selectArr: [] //要传入的数据
                },
                SHOW_PARENT,
                listArr: [], //中心列表
                type_val: undefined,
                id: '', //中心id
                colon:false,
                visible: false,
                leftShow: true,
                hintMsg: false,
                treeDataSchool: [],
                schoolVal: [],
                contentHeight: 600,

                schoolDetails: {}, //中心详情信息

                province_arr: [], //省
                province: [],

                //详情
                orgName: '',
                centerCode: '', //简写
                region: '', //所属大区
                level_arr: [
                    {value: 1, name: '一线城市'},
                    {value: 2, name: '二线城市一档'},
                    {value: 3, name: '二线城市二档'},
                    {value: 4, name: '二线城市三档'}
                ],
                level_arrAdd: [
                    {value: 1, name: '二线城市'},
                    {value: 2, name: '三线城市'},
                    {value: 3, name: '四线城市'},
                    {value: 4, name: '五线城市'}
                ],
                display_arr: [
                    {value: 0, name: '不显示'},
                    {value: 1, name: '显示'}
                ],
                level: undefined, //城市等级
                displayCode: undefined, //是否官网显示 1 显示 0否
                town: '', //街道
                detailAddress: '', //详情地址
                chargeId: '', //负责人
                mapPoint: '', //经纬度
                phone: '', //中心电话
                schoolCode: '', //schoolcode
                classRoomNum: '', //教室数量
                parkingSpace: '', //停车位数量
                intro: '', //备注schoolCenter
                partnerType: 0,
                parentId: '',
                BusinessInfo: '',
                // 图片
                centerList: [], //中心照片
                mapList: [], //中心地图
                sealList: [], //中心公章
                BusinessLicenseList:[],//营业执照
                Business:[],
                BusinessStatus:true,
                centerBool: true,
                mapBool: true,
                sealBool: true,
                //施工信息
                openingTime: '2019-12-12', //开业时间
                buildDate: '2019-12-12', //装修时间
                signDate: '2019-12-12', //鉴定时间
                trialOpeningTime: '2019-12-12', //试营业时间
                completedDate: '2019-12-12', //交工时间
                value: '', //面积

                previewVisible: false,
                previewImage: '',
                fileList: [],
                //施工信息
                // birthday:'2019-12-12',
                value: '', //面积

                transXY: {} //位置转换的经纬度
                // transAddress:'',//要转的地理位置
            }
        },
        created() {
            let that = this
            that.$axios.get(8008, {}, res => {
                console.log(res)
                if (res.data.code == 1) {
                    that.treeDataSchool = res.data.data
                } else {
                    that.$message.errpr(res.data.msg)
                }
            })
            that.ajaxCityFn(undefined, 1)
        },
        mounted() {
            this.contentHeight = this.$refs.totalHeight.clientHeight
            console.log(this.contentHeight)
            this.listAjaxFn()
        },
        methods: {
            moment,
            formatNumber,
            birthdayChange(value) {
                // if(!value){return false;}
                let time = value._d
                let date1 = new Date(time)
                let birthday =
                    date1.getFullYear() +
                    '-' +
                    (date1.getMonth() + 1 < 10
                        ? '0' + (date1.getMonth() + 1)
                        : date1.getMonth() + 1) +
                    '-' +
                    (date1.getDate() < 10 ? '0' + date1.getDate() : date1.getDate())
                // console.log(this.birthday)
            },
            // 限制生日选择的范围
            disabledDate(current) {
                return current && current > moment().endOf('day')
            },
            //转换经纬度
            transXYFn(address) {
                let that = this
                $.ajax({
                    url:
                        'https://api.map.baidu.com/geocoder/v2/?output=json&ak=GifbRye3VIFOU6L3WVh61ZYf&address=' +
                        address,
                    type: 'GET',
                    dataType: 'jsonp',
                    contentType: 'application/json;charset=utf-8',
                    success: function (res) {
                        console.log(res)
                        that.transXY = res.result
                        that.createMap()
                    }
                })
            },
            //上传图片
            handleCancel(file) {
                this.previewVisible = false
            },
            //查看图片
            handlePreview(file) {
                console.log(file.name);
                let filename = file.name.split('.');
                let type = filename[(filename.length-1)];
                let typeArr = ['png','jpg','jpeg','gif','PNG','JPEG','GIF'];
                if(typeArr.indexOf(type) == -1){
                    window.open(file.url+'?attname='+file.name);
                    return false;
                }
                this.previewImage = file.url || file.thumbUrl
                this.previewVisible = true
            },
            //图片添加与删除会触发
            handleChange({fileList}) {
                // console.log('cx',fileList);
                this.centerList = fileList
                if (this.centerBool) {
                    this.centerList = fileList
                }
                // this.callbackFn( this.centerList,9955);
            },
            handleChangeMap({fileList}) {
                this.mapList = fileList
                if (this.mapBool) {
                    this.mapList = fileList
                }
                // this.callbackFn( this.mapList,9954);
            },
            handleChangeSeal({fileList}) {
                if (this.sealBool) {
                    this.sealList = fileList
                }
                // this.callbackFn( this.sealList,9953);
            },
            handleChangeBusiness({fileList}){
                console.log(fileList)
                 if(this.BusinessStatus){
                     this.BusinessLicenseList = fileList;
                 }
            },

            //相片上传
            //删除
            handleRemove(file) {
                // console.log('del',file)
                const index = this.centerList.indexOf(file)
                const newFileList = this.centerList
                newFileList.splice(index, 1)
                this.centerList = newFileList
                // console.log(newFileList)
                this.callbackFn(newFileList, 9955)
                this.centerBool = false
            },
            handleRemoveMap(file) {
                const index = this.mapList.indexOf(file)
                const newFileList = this.mapList
                newFileList.splice(index, 1)
                this.mapList = newFileList
                this.callbackFn(newFileList, 9954)
                this.mapBool = false
            },
            handleRemoveSeal(file) {
                const index = this.sealList.indexOf(file)
                const newFileList = this.sealList
                newFileList.splice(index, 1)
                this.sealList = newFileList
                this.callbackFn(newFileList, 9953)
                this.sealBool = false
            },
            handleRemoveBusiness(file){
                let self = this;

                if(this.Business.length ==1){
                    this.Business = [];
                    this.BusinessLicenseList =[];
                }else{
                    let num = 0 ;
                    for(let i=0;i<self.BusinessLicenseList.length;i++){
                        if(self.BusinessLicenseList[i].url ==file.url ){
                            num = i;
                        }
                    }
                    this.Business.splice(num,1);
                    this.BusinessLicenseList.splice(num,1);
                }

                this.callbackFn(this.Business, 995523)
                this.BusinessStatus = false
            },
            //相片上传 中心，地图，公章
            beforeUpload(file) {
                let that = this
                that.uploadPublicFn(file, 'school')
                return false
            },
            beforeUploadMap(file) {
                let that = this
                that.uploadPublicFn(file, 'map')
                return false
            },
            beforeUploadSeal(file) {
                let that = this
                that.uploadPublicFn(file, 'seal')
                return false
            },
            beforeUploadBusinessLicense(file){
               console.log(file)
                this.uploadPublicFn(file,'BusinessLicense');
               return false;
            },
            uploadPublicFn(file, type) {
                let that = this;
                if(type !='BusinessLicense'){
                    const isIMG = file.type === 'image/jpeg' || file.type === 'image/png'
                    if (!isIMG) {
                        this.$message.error('请上传jpg或png格式的照片')
                        return false
                    }
                }

                if (type == 'school') {
                    if (!that.centerBool) {
                        return false
                    }
                }
                if (type == 'map') {
                    if (!that.mapBool) {
                        return false
                    }
                }
                if (type == 'seal') {
                    if (!that.sealBool) {
                        return false
                    }
                }
                if(type =='BusinessLicense'){
                    if(!that.BusinessStatus){
                        return false;
                    }
                }
                this.__uploadFile__([file]).then(res => {
                    // console.log(res);
                    let obj = res[0]
                    if (!obj.FileUrl) {
                        this.$message.error('上传失败!')
                        return false
                    }

                    if(type=='BusinessLicense'){
                         console.log(res);
                        // BusinessLicenseList

                        for(let i=0;i<res.length;i++){
                            that.Business.push(res[i]);
                            let  BusinessObject  = {};
                            BusinessObject.name = res[i].FileName;
                            BusinessObject.uid = '-'+ this.BusinessLicenseList.length;
                            BusinessObject.status = 'done';
                            BusinessObject.url = res[i].FileUrl;
                            // that.BusinessLicenseList.push(BusinessObject);
                        }
                        that.callbackFn(that.Business, 995523)
                        return false
                    }
                    if (type == 'school') {
                        let objFile =
                            that.centerList.length > 0
                                ? that.centerList[that.centerList.length - 1]
                                : {}
                        objFile.url = obj.FileUrl
                        if (that.centerList.length == 1) {
                            that.centerList = []
                        } else {
                            that.centerList.splice(that.centerList.length - 1, 1)
                        }
                        that.centerList.push(objFile)
                        that.callbackFn(that.centerList, 9955)
                        return false
                    }
                    if (type == 'map') {
                        let objFile =
                            that.mapList.length > 0 ? that.mapList[that.mapList.length - 1] : {}
                        objFile.url = obj.FileUrl
                        if (that.mapList.length == 1) {
                            that.mapList = []
                        } else {
                            that.mapList.splice(that.mapList.length - 1, 1)
                        }
                        that.mapList.push(objFile)
                        that.callbackFn(that.mapList, 9954)
                        return false
                    }
                    if (type == 'seal') {
                        let objFile =
                            that.sealList.length > 0
                                ? that.sealList[that.sealList.length - 1]
                                : {}
                        objFile.url = obj.FileUrl
                        if (that.sealList.length == 1) {
                            that.sealList = []
                        } else {
                            that.sealList.splice(that.sealList.length - 1, 1)
                        }
                        that.sealList.push(objFile)
                        that.callbackFn(that.sealList, 9953)
                    }
                })
            },
            callbackFn(arr, urlCode) {
                //图片路径保存到后台

                let that = this
                if (urlCode == 9953) {
                    that.sealBool = true
                }
                if (urlCode == 9954) {
                    that.mapBool = true
                }
                if (urlCode == 9955) {
                    that.centerBool = true
                }
                let str;
                if(urlCode==995523){
                    console.log(arr);
                    if(arr.length==0){
                        str = '';
                    }else{
                        str =  JSON.stringify(arr);
                    }
                    that.BusinessStatus = true;
                }else{
                    if (arr.length > 0) {
                        let array = []
                        for (let i = 0; i < arr.length; i++) {
                            // console.log(arr[0].url);
                            array.push(arr[i].url)
                        }
                        // console.log(array)
                        str = array.join(',')
                        // console.log(str)
                    } else {
                        str = ''
                    }
                }

                let data = {
                    Id:that.id,
                    Url: str,
                    UserId: userInfo.uid,

                }
                // console.log(str)
                that.$axios.post(
                    urlCode,
                    {Id: that.id, Url: str, UserId: userInfo.uid},
                    res => {
                        // console.log(res)
                        if (res.data.code != 1) {
                            that.$message.error(res.data.msg)
                            return false
                        }
                    }
                )
            },
            //选中心
            onChangeSchool(value) {
                // console.log(value)
                this.schoolVal = value
                this.transXY = {}
                this.listAjaxFn(value.join(','))
            },
            _hintShow() {
                this.hintMsg = !this.hintMsg
            },
            _cutShow() {
                this.leftShow = !this.leftShow
                this.hintMsg = false
            },
            onClose() {
                // this.orgName = '添加中心';
                this.visible = !this.visible;
                if(!this.visible){
                    console.log(222)
                    this.BusinessLicenseList =[];
                    this.Business =[];
                    this.BusinessStatus =true;
                    this.listAjaxFn(this.schoolVal.join(','));

                }
            },
            //地图
            createMap() {
                let that = this
                let map = new BMap.Map('SchoolCenter')
                let mapPoints = that.listArr
                map.clearOverlays() //清空地图上标注点
                let a = mapPoints[0].MapPoint
                    ? mapPoints[0].MapPoint.split(',')[0]
                    : 113.936979
                let b = mapPoints[0].MapPoint
                    ? mapPoints[0].MapPoint.split(',')[1]
                    : 22.524739
                let lng = a > 100 ? a : b
                let lat = b > 100 ? a : b

                if (that.transXY.location) {
                    let XY = that.transXY.location.lng + ',' + that.transXY.location.lat
                    mapPoints.unshift({
                        MapPoint: XY,
                        Phone: '',
                        Addr: '123456789987654123',
                        OrgName: 'kehu1'
                    })
                    map.centerAndZoom(
                        new BMap.Point(that.transXY.location.lng, that.transXY.location.lat),
                        12
                    )
                } else {
                    map.centerAndZoom(new BMap.Point(lng, lat), 8)
                }
                let num = 0
                for (let i = 0; i < mapPoints.length; i++) {
                    if (mapPoints[i].MapPoint) {
                        num++

                        let myIcon
                        if (that.transXY.location && num == 1) {
                            let size = new BMap.Size(50, 50)
                            myIcon = new BMap.Icon(
                                'https://smart-resource.sikegroup.com/3f11705fe59c2c60d7bab8182a3e4e6c.png',
                                size
                            )
                        } else {
                            let size = new BMap.Size(36, 36)
                            myIcon = new BMap.Icon(
                                mapPoints[i].PartnerType == 2
                                    ? 'https://smart-resource.sikegroup.com/saas/image/mapIcon2.png'
                                    : 'https://smart-resource.sikegroup.com/saas/image/mapIcon.png',
                                size
                            )
                        }
                        let jina = mapPoints[i].MapPoint.split(',')[0]
                        let weib = mapPoints[i].MapPoint.split(',')[1]
                        let jin = jina > 100 ? jina : weib
                        let wei = weib > 100 ? jina : weib
                        let marker = new BMap.Marker(new BMap.Point(jin, wei), {
                            icon: myIcon
                        }) // 创建标注
                        let content = `<div>
                                            <div>${mapPoints[i].OrgName}</div>
                                            <div style="font-size:12px;margin-top:10px;">电话号码: ${
                            mapPoints[i].Phone
                            }</div>
                                            <div style="font-size:12px;">地址:  ${mapPoints[
                            i
                            ].Addr.slice(
                            0,
                            18
                        )} <br/> <span style="margin-left:30px;">${mapPoints[
                            i
                            ].Addr.substring(18)}</span></div>
                                       </div>
                                        `
                        let opts = {
                            maxWidth: 220
                        }
                        let infoWindow = new BMap.InfoWindow(content, opts)
                        marker.addEventListener('mouseover', function (e) {
                            let p = e.target
                            let scaleVal = map.getZoom()
                            let point = new BMap.Point(
                                p.getPosition().lng,
                                p.getPosition().lat + 4.8 / Math.pow(2, scaleVal - 3)
                            )
                            // 创建信息窗口对象
                            map.openInfoWindow(infoWindow, point)
                        })
                        marker.addEventListener('mouseout', function () {
                            map.closeInfoWindow(infoWindow)
                        })
                        marker.addEventListener('click', function (e) {
                            that.id = mapPoints[i].Id
                            that.schoolDetailsFn(mapPoints[i].Id)
                        })
                        map.addOverlay(marker) // 将标注添加到地图中
                    }
                }
                map.enableScrollWheelZoom(true) //开启鼠标滚轮缩放
                console.log(num)
            },

            //省市区三级联动
            ajaxCityFn() {
                let that = this
                that.$axios.get(8010, {}, res => {
                    // console.log(res.data.data)
                    if (res.data.code == 1) {
                        that.province_arr = res.data.data
                    } else {
                        that.$message.error(res.data.msg)
                    }
                })
            },
            ChangeAddr(val) {
                console.log(val)
                this.province = val
            },
            //施工信息 面积
            onChange(e) {
                const {value} = e.target
                const reg = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/
                if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
                    this.value = value
                }
            },
            // '.' at the end or only '-' in the input box.
            onBlur() {
                const {value, onChange} = this
                if (value.charAt(value.length - 1) === '.' || value === '-') {
                    onChange({value: value.slice(0, -1)})
                }
            },

            //list列表
            listAjaxFn(id) {
                let num = 0
                if (id) {
                    num++
                }
                let that = this
                that.spinning = true
                let obj = {}
                if (id) {
                    obj = {Id: id}
                }
                type_Arr = []
                that.listArr = []
                that.$axios.get(9957, obj, res => {
                    that.spinning = false
                    if (res.data.code == 1) {
                        that.listArr = res.data.data
                        type_Arr = res.data.data
                        //    console.log(type_Arr,that.listArr)
                        //    if(num>0){ that.meList();}else{
                        that.createMap()
                        //    }
                    } else {
                        that.$message.error(res.data.msg)
                    }
                })
            },
            //分类筛选

            meList() {
                let ing = [] //筹备
                let that = this
                this.transXY = {}
                let start = [] //开业
                let arr = type_Arr
                if (that.type_val && type_Arr.length > 0) {
                    that.listArr = []
                    for (let i = 0; i < arr.length; i++) {
                        if (arr[i].OrgName.indexOf('筹备') > 1) {
                            ing.push(arr[i])
                        } else {
                            start.push(arr[i])
                        }
                    }
                    that.listArr = that.type_val == 1 ? start : ing
                    // console.log(that.listArr)
                }
                that.createMap()
            },
            //中心详情

            schoolDetailsFn(id) {
                // let userInfo = JSON.parse(window.sessionStorage.getItem('userInfo'));//判断是否有权限改
                // if()
                let that = this
                that.id = id
                that.spinning = true

                that.$axios.get(9956, {Id: id}, res => {
                    console.log(123456);
                    that.spinning = false
                    if (res.data.code == 1) {
                        let obj = res.data.data.org
                        let objAddr = res.data.data.school
                        //   that.schoolDetails = res.data.data;
                        that.orgName = obj.OrgName
                        that.centerCode = obj.Code //简写
                        that.displayCode = obj.Display //是否官网显示 1 显示 0否
                        that.level = obj.CityLevel //城市等级
                        that.mapPoint = obj.MapPoint //经纬度
                        that.phone = obj.Phone //中心电话
                        that.schoolCode = obj.SchoolCode //schoolcode
                        that.classRoomNum = obj.ClassRoomNum //教室数量
                        that.company = obj.Company //所属公司
                        that.parkingSpace = obj.ParkingSpace //停车位数量
                        that.partnerType = obj.PartnerType //加盟是2合伙1 直营0
                        that.parentId = obj.ParentId
                        that.BusinessInfo = obj.BusinessInfo

                        if (objAddr) {
                            that.region = objAddr.Region //所属大区
                            that.town = objAddr.Town //街道
                            that.detailAddress = objAddr.DetailAddress //详情地址
                            that.chargeId = objAddr.ChargeId //负责人
                            // that.province = [
                            //     Number(objAddr.Province),
                            //     Number(objAddr.City),
                            //     Number(objAddr.Area)
                            // ];
                            that.province = [objAddr.Province,objAddr.City,objAddr.Area];
                            that.intro = objAddr.StoreDescription //备注
                        }
                        console.log(that.province);
                        //   that.province.push(objAddr.Province);
                        //   that.province.push(objAddr.City);
                        //   that.province.push(objAddr.Area);

                        // 图片
                        that.centerList = imgObjFn(obj.Images) //中心照片
                        that.mapList = imgObjFn(obj.MapImage) //中心地图
                        that.sealList = imgObjFn(obj.SealImage) //中心公章
                        if(obj.BusinessLicense){
                            that.Business = obj.BusinessLicense;
                            for(let i=0;i<obj.BusinessLicense.length;i++){
                                 let  BusinessObject  = {};
                                BusinessObject.name = obj.BusinessLicense[i].FileName;
                                BusinessObject.uid = '-'+ i;
                                BusinessObject.status = 'done';
                                BusinessObject.url = obj.BusinessLicense[i].FileUrl;
                                that.BusinessLicenseList.push(BusinessObject);
                            }
                        }


                        //施工信息
                        that.openingTime = that.__moment__(
                            obj.OpeningTime
                                ? obj.OpeningTime.length > 10
                                ? chengeTime(obj.OpeningTime)
                                : obj.OpeningTime
                                : '1970-01-01'
                        ) //开业时间
                        that.buildDate = that.__moment__(
                            obj.BuildDate ? obj.BuildDate : '1970-01-01'
                        ) //装修时间
                        that.signDate = that.__moment__(
                            obj.SignDate ? obj.SignDate : '1970-01-01'
                        ) //鉴定时间
                        that.trialOpeningTime = that.__moment__(
                            obj.TrialOpeningTime ? obj.TrialOpeningTime : '1970-01-01'
                        ) //试营业时间
                        that.completedDate = that.__moment__(
                            obj.CompletedDate ? obj.CompletedDate : '1970-01-01'
                        ) //交工时间
                        that.value = obj.Square //面积
                        that.visible = true
                    } else {
                        that.$message.error(res.data.msg)
                    }
                })
            },
            //选人
            getData(val) {
                console.log(val, '选人')
                let arr = []
                // arr.push(val[0].icon);
                arr.push(val[0].isuser)
                arr.push(val[0].id)
                // arr.push(val[0].poition);
                arr.push(val[0].title)
                this.chargeId = arr.join('|')
            },
            //基本信息提交
            infoFn() {
                let that = this
                if (!that.orgName) {
                    that.$message.error('名称不能为空')
                    return false
                }
                let obj = {
                    Id: that.id,
                    UserId: userInfo.uid,
                    OrgName: that.orgName,
                    Code: that.centerCode,
                    Region: that.region,
                    Display: that.displayCode ? that.displayCode : 0,
                    Town: that.town,
                    DetailAddress: that.detailAddress, //详情地址
                    ChargeId: that.chargeId, //负责人---
                    MapPoint: that.mapPoint, //经纬度
                    Phone: that.phone, //中心电话
                    SchoolCode: that.schoolCode, //schoolcode
                    ClassRoomNum: that.classRoomNum, //教室数量---
                    Company:that.company,
                    ParkingSpace: that.parkingSpace, //停车位数量
                    StoreDescription: that.intro, //备注
                    Province: that.province[0],
                    City: that.province[1],
                    Area: that.province[2],
                    CityLevel: that.level,
                    ParentId: that.parentId,
                    BusinessInfo: that.BusinessInfo
                }
                that.$axios.post(9952, obj, res => {
                    console.log(res.data)
                    if (res.data.code == 1) {
                        that.$message.success('提交成功')
                        // that.listArr.unshift(res.data.org);
                    } else {
                        that.$message.error(res.data.msg)
                    }
                })
            },
            constructionFn() {
                let that = this
                let obj = {
                    Id: that.id,
                    UserId: userInfo.uid,
                    OpeningTime: changeTime(that.openingTime), //开业时间
                    BuildDate: changeTime(that.buildDate), //装修时间
                    SignDate: changeTime(that.signDate), //鉴定时间
                    TrialOpeningTime: changeTime(that.trialOpeningTime), //试营业时间
                    CompletedDate: changeTime(that.completedDate), //交工时间
                    Square: that.value //面积
                }
                that.$axios.post(9951, obj, res => {
                    console.log(res.data)
                    if (res.data.code == 1) {
                        that.$message.success('提交成功')
                    } else {
                        that.$message.error(res.data.msg)
                    }
                })
            }
        }
    }
</script>

<style scoped lang="less">
    .SchoolCenter {
        position: relative;
        .left-container {
            position: absolute;
            top: 50%;
            left: 15px;
            transform: translateY(-50%);
            height: 95%;
            background: #fff;
        }
        .left-main {
            max-width: 350px;
            width: 340px;
            height: 100%;
            border-radius: 2px;
            position: relative;
        }
        .add-btn {
            position: absolute;
            bottom: 10px;
            right: 10px;
            width: 45px;
            height: 45px;
            line-height: 45px;
            text-align: center;
            border-radius: 50%;
            color: #fff;
            cursor: pointer;
        }
        .cut-btn {
            position: absolute;
            top: 10px;
            right: -20px;
            width: 20px;
            height: 40px;
            line-height: 38px;
            background: #fff;
            border: 1px solid #e4e5e6;
            cursor: pointer;
            .hint-msg {
                position: absolute;
                top: 50%;
                right: -85px;
                transform: translateY(-50%);
                background: #000;
                opacity: 0.7;
                padding: 4px 8px;
                white-space: nowrap;
                font-size: 10px;
                line-height: 1.6;
                color: #fff;
                border-radius: 2px;
                .san {
                    position: absolute;
                    top: 50%;
                    left: -5px;
                    transform: translateY(-50%);
                    border-right: 5px solid #000;
                    border-top: 5px solid transparent;
                    border-bottom: 5px solid transparent;
                }
            }
        }
        .list-content {
            padding: 0px 15px;
            overflow-y: auto;
            position: absolute;
            width: 100%;
            left: 0px;
            ul {
                li {
                    position: relative;
                    padding: 8px 16px;
                    margin-bottom: 20px;
                    .list-name {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-bottom: 8px;
                        span:first-child {
                            font-size: 16px;
                            font-weight: 400;
                        }
                        span:last-child {
                            font-size: 14px;
                        }
                    }
                    box-shadow: 0px 2px 8px 0px rgba(117, 113, 249, 0.13);
                    .line {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 3px;
                        height: 100%;
                        border-top-left-radius: 4px;
                        border-bottom-left-radius: 4px;
                    }
                }
            }
            .site {
                font-size: 12px;
            }
        }
    }

    .drawer-content {
        padding-bottom: 40px;
    }

    .list-title {
        font-size: 18px;
        color: #333333;
    }

    .drawer-bottom {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        border-top: 1px solid #e9e9e9;
        padding: 10px 16px;
        background: #fff;
        text-align: left;
        z-index: 1000;
    }

    .show-enter-active {
        width: 350px;
        opacity: 1;
        transition: all 0.3s ease;
    }

    .show-leave-active {
        width: 350px;
        opacity: 1;
        transition: all 0.3s ease;
    }

    .show-enter,
    .show-leave-to {
        width: 0;
        opacity: 0;
    }

    .zIndex {
        background-color: #ffffff;
        z-index: 10;
        padding-left: 12px;
        position: relative;
        margin-bottom: 16px;
    }

    .ant-form-item {
        margin-bottom: 14px;
    }

    .ant-upload-select-picture-card i {
        font-size: 32px;
        color: #999;
    }

    .ant-upload-select-picture-card .ant-upload-text {
        margin-top: 8px;
        color: #666;
    }

    .post_map {
        position: fixed !important;
        bottom: 100px !important;
        left: 220px;
        z-index: 10;
    }

    .line-gray {
        background: #bbbcbc;
    }
</style>
